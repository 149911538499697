(function($) {

  // START IFRAME RESIZE

  var $iframes = $( "iframe" );
  // Find & save the aspect ratio for all iframes
  $iframes.each(function () {
    $( this ).data( "ratio", parseInt(this.height) / parseInt(this.width) )
      // Remove the hardcoded width & height attributes
      .removeAttr( "width" )
      .removeAttr( "height" );
  });

  // Resize the iframes when the window is resized
  $( window ).resize( function () {
    $iframes.each( function() {
      // Get the parent container's width
      var width = $( this ).parent().width();
      $(this).width( width )
      .height( width * $( this ).data( "ratio" ) );
    });
  // Resize to fix all iframes on page load.
  }).resize();

  // END IFRAME RESIZE




  // START SMOOTHSCROLL
  $("nav ul li a[href^='#']").on('click', function(e) {
     // prevent default anchor click behavior
     e.preventDefault();
     // store hash
     var hash = this.hash;

     // Collapse menu
    $('.navbar-collapse').collapse('hide');

     // animate
     $('html, body').animate({
         scrollTop: $(hash).offset().top
       }, 300, function(){

         // when done, add hash to url
         // (default click behaviour)
         window.location.hash = hash;
       });
  });
  // END SMOOTHSCROLL

  if ($('.slider > div').length>1) {
    $('.slider').flickity({
      cellAlign: 'left',
      wrapAround:true,
      pageDots: false,
      autoPlay: true
    });
  }


  // Parallax plugin
  skrollr.init();



  // Calculate correct contact columns
  var contact_columns = $('.contact .row > div').length;
  $('.contact .row').addClass('no-'+contact_columns);

  $('.ingress-read-more').click(function() {
    $('.ingress-more').collapse('show');
    $(this).css('cursor','default').animate({ opacity: 0}, 'slow');
  });



})(jQuery);


// News text fixes
(function($, viewport){
  $(window).resize(
    viewport.changed(function() {
      if(viewport.is('xs')) {
        // Reset news text (both normal and "alt") width on smaller screens
        $('.story-text').innerWidth('auto').css('right','auto');
      } else {
        // Make the .news .story-text fixed width
        // while the .story-image has width 50%.
        var w = $('.navbar .container').innerWidth();
        $('.story-text').innerWidth(w/2);

        // Pull all texts that are alt
        $('.row:odd .story-text').innerWidth(w/2).css('right',w/2);
      }
    },10)
  ).resize();
})(jQuery, ResponsiveBootstrapToolkit);
